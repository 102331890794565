/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@-webkit-keyframes __01xnxy8Y {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@keyframes __01xnxy8Y {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@-webkit-keyframes viISd8GB {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes viISd8GB {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.LfqDhT91 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.__8YY6W4Zv {
  position: relative;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.XbeRLcMe .rGND8S3s {
  display: flex;
}
.XbeRLcMe .rGND8S3s .__0GW3o5Ns {
  padding-left: 1.5rem;
  padding-right: 2.0625rem;
  border-right: 1px solid var(--primary-color-white);
}
.XbeRLcMe .rGND8S3s .__0GW3o5Ns .l5cje5Yk {
  font-size: 36px;
  font-weight: bold;
  color: var(--primary-color-white);
  font-family: var(--font-family);
}
.XbeRLcMe .rGND8S3s .__0GW3o5Ns .hMJvdDK3 {
  font-size: 16px;
  color: var(--primary-color-white);
  font-family: var(--font-family);
}
.XbeRLcMe .rGND8S3s .__0GW3o5Ns:last-child {
  padding-right: 0;
  border-right: none;
}
.XbeRLcMe .HLFs455V {
  margin-bottom: 50px;
}
.XbeRLcMe .HLFs455V .wwIFz39a {
  margin-left: 1.5rem;
  text-decoration: underline;
  color: var(--primary-color-white);
}
.XbeRLcMe .Lh33fRMb {
  border-radius: 4px;
  font-size: 16px;
  width: 8.75rem;
  height: 40px;
  text-align: center;
  color: var(--primary-color-white);
}
.XbeRLcMe .CZ12j_kI {
  background-color: var(--primary-color-blue);
}
.XbeRLcMe .GN2lePZa {
  background-color: transparent;
}
.XbeRLcMe .tETb8PWe {
  font-family: var(--font-family);
  color: var(--primary-color-white);
  margin-bottom: 12px;
}
.XbeRLcMe .FVm2dYhB {
  line-height: 24px;
  font-size: 14px;
  font-family: var(--font-family);
  color: var(--primary-color-white);
}
@media (min-width: 1200px) {
  .wicWONhx {
    padding: 5rem 0;
    background: url('/img/index_banner_home.png') no-repeat center center;
    background-size: cover;
  }
  .wicWONhx .__4At2ECcI {
    margin: 0 auto;
    width: var(--max-width);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .wicWONhx .__4At2ECcI .XbeRLcMe {
    background-size: cover;
  }
  .tETb8PWe {
    font-size: 36px;
  }
  .FVm2dYhB {
    margin-bottom: 28px;
  }
}
@media (max-width: 1199px) and (min-width: 0px) {
  .XbeRLcMe {
    padding: 2.1875rem 1rem;
    background: url('/img/banner_m.png') no-repeat center center;
    background-size: cover;
  }
  .tETb8PWe {
    font-size: 20px;
  }
  .FVm2dYhB {
    margin-bottom: 24px;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ulNGZTWN .Ij5q1_iE {
  margin: 0 auto;
  max-width: 1440px;
  position: relative;
}
.ulNGZTWN .Ij5q1_iE .nL4AaGJZ {
  margin: 0 auto;
  width: var(--max-width);
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: var(--primary-color-white);
  bottom: 70px;
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px rgba(70, 97, 166, 0.15);
  box-sizing: border-box;
  padding: 2rem 1.5rem;
}
.ulNGZTWN .Ij5q1_iE .nL4AaGJZ .BTRsMlYW {
  text-align: center;
  flex: 1;
  margin: 0 1.25rem;
}
.ulNGZTWN .Ij5q1_iE .nL4AaGJZ .BTRsMlYW:first-child {
  margin-left: 0;
}
.ulNGZTWN .Ij5q1_iE .nL4AaGJZ .BTRsMlYW:last-child {
  margin-right: 0;
}
.ulNGZTWN .Ij5q1_iE .nL4AaGJZ .BTRsMlYW .WvugHwpG {
  width: 1.75rem;
  height: 28px;
  margin-bottom: 12px;
}
.ulNGZTWN .Ij5q1_iE .nL4AaGJZ .BTRsMlYW .SdGkgVqW {
  font-size: 18px;
  font-family: var(--font-family);
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 12px;
  color: var(--primary-color-black);
}
.ulNGZTWN .Ij5q1_iE .nL4AaGJZ .BTRsMlYW .UX6q3N5_ {
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: 400;
  text-align: center;
  line-height: 20px;
  color: var(--primary-light-grey);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@-webkit-keyframes __251wN_VO {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@keyframes __251wN_VO {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@-webkit-keyframes Ud5Xafei {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes Ud5Xafei {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
._PyYZg1F {
  display: flex;
  justify-content: center;
  align-items: center;
}
.COkn0rAV {
  width: 6.25rem;
  top: 574px;
  position: fixed;
  height: 44px;
  left: 0;
  background: #0082fc;
  border-radius: 8px;
  box-shadow: 0px 0px 6px 0px rgba(179, 193, 211, 0.3);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.COkn0rAV .I680c26s {
  margin-left: 0.5rem;
}
.W1EEFkX8 {
  width: 6.25rem;
  border-radius: 5px;
  box-shadow: 0px 0px 7px 0px rgba(70, 97, 166, 0.15);
  position: fixed;
  top: 348px;
  background-color: var(--primary-color-white);
  left: 0;
  box-sizing: border-box;
  padding: 0.3125rem 0;
}
.W1EEFkX8 .yWv3CHEE {
  padding: 0.625rem 0;
  cursor: pointer;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
}
.W1EEFkX8 .yWv3CHEE:hover {
  background-color: #F3F8FF;
}
.fUczFc31 .yWv3CHEE {
  color: #1890FF !important;
}
.C7MZUwBL {
  padding: 1.125rem 1rem;
  box-sizing: border-box;
}
.C7MZUwBL .sGSYVsah {
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: 600;
  text-align: center;
  color: var(--primary-grey-text);
  margin-bottom: 12px;
}
.C7MZUwBL .UQojdg9w {
  width: 7.5rem;
  height: 120px;
}
.C7MZUwBL .__0aXS1ArX {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color-black);
  font-size: 12px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.OxrO0U2I ._lb9d43k {
  font-family: var(--font-family);
  font-weight: bold;
  color: #17243e;
  text-align: center;
  font-size: 28px;
}
.OxrO0U2I .__3IRwdzVh {
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: bold;
  color: #8496ab;
  text-align: center;
}
.OxrO0U2I .YU28govb {
  overflow: hidden;
}
.OxrO0U2I .YU28govb .ant-collapse {
  border: 1px solid #eef3fa;
}
.OxrO0U2I .YU28govb .dd0pVK6A {
  margin: 0.5rem 0;
}
.OxrO0U2I .YU28govb .dd0pVK6A .__2gt75Ccd {
  background: linear-gradient(270deg, #00baa1 99%, #00d696 100%);
}
.OxrO0U2I .YU28govb .dd0pVK6A .B3IeSG34 {
  background: linear-gradient(270deg, #ff7c6b 99%, #ff4b72);
}
.OxrO0U2I .YU28govb .dd0pVK6A .FmdnY_Bw {
  background: var(--primary-color-blue);
}
.OxrO0U2I .YU28govb .dd0pVK6A .__4oBsLPGG {
  color: #00BAA1;
}
.OxrO0U2I .YU28govb .dd0pVK6A .FsI1A5KW {
  color: var(--primary-color-blue);
}
.OxrO0U2I .YU28govb .dd0pVK6A .G8gxW_D8 {
  background-color: #F3F7FA;
}
.OxrO0U2I .YU28govb .dd0pVK6A .G8gxW_D8 .ant-collapse-header {
  padding: 1.25rem 1rem !important;
  border-top: 1px solid #DDE7F5;
  border-left: 1px solid #DDE7F5;
  border-right: 1px solid #DDE7F5;
}
.OxrO0U2I .YU28govb .dd0pVK6A .G8gxW_D8 .esaYeHfZ {
  font-size: 12px;
  font-family: var(--font-family);
  color: #a4a9b5;
}
.OxrO0U2I .YU28govb .dd0pVK6A .G8gxW_D8 .Q5FLN8fZ {
  display: flex;
  align-items: center;
}
.OxrO0U2I .YU28govb .dd0pVK6A .G8gxW_D8 .Q5FLN8fZ .x8p6HRTs {
  font-size: 16px;
  font-family: var(--font-family);
  font-weight: bold;
  color: #17243e;
}
.OxrO0U2I .YU28govb .dd0pVK6A .G8gxW_D8 .Q5FLN8fZ .__3_hGgi7a {
  margin-left: 0.5rem;
  border-radius: 4px;
  width: 2.375rem;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: var(--primary-color-white);
}
.OxrO0U2I .YU28govb .dd0pVK6A .N9vvfACr {
  padding: 0.8125rem 0;
  border-bottom: 1px solid #eef3fa;
  padding-left: 1rem;
  font-size: 14px;
  font-family: var(--font-family);
  color: var(--primary-color-black);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
}
.OxrO0U2I .YU28govb .dd0pVK6A .N9vvfACr:last-child {
  border-bottom: none;
}
.OxrO0U2I .YU28govb .dd0pVK6A .N9vvfACr .eOkLlF7f {
  display: flex;
  align-items: center;
}
.OxrO0U2I .YU28govb .dd0pVK6A .N9vvfACr .eOkLlF7f .mEKgEp7k {
  font-size: 14px;
}
.OxrO0U2I .YU28govb .dd0pVK6A .N9vvfACr .eOkLlF7f .B22dx28h {
  margin-left: 0.5rem;
  width: 1rem;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .QWBNP3Vb {
  font-size: 18px;
  font-weight: bold;
  padding: 0 1.25rem;
  background: #FFF2E7;
  color: #FA3A43;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .y83n6hxl {
  width: 100%;
  max-width: 1200px;
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .y83n6hxl .B61t3ohw {
  border-bottom: 4px solid #DDE7F5;
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .y83n6hxl .B61t3ohw ._O6Raq1U {
  box-sizing: border-box;
  vertical-align: bottom;
  height: 80px;
  padding: 0;
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .y83n6hxl .B61t3ohw ._O6Raq1U .__5JUwRciG {
  background-color: transparent;
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .y83n6hxl .B61t3ohw ._O6Raq1U .VHR0NItf {
  padding: 0 !important;
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .y83n6hxl .B61t3ohw ._O6Raq1U .L1Ziui28 {
  padding: 1.25rem 1rem;
  border-left: 1px solid #DDE7F5;
  border-right: 1px solid #DDE7F5;
  border-top: 1px solid #DDE7F5;
  background-color: #F3F7FA;
  border-bottom: 0;
  border-radius: 8px 0px 0px 0px;
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .y83n6hxl .B61t3ohw ._O6Raq1U:first-child {
  width: 7.5rem;
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .y83n6hxl .B61t3ohw ._O6Raq1U .LAZG3EoF {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .y83n6hxl .B61t3ohw ._O6Raq1U .LAZG3EoF ._lb9d43k {
  font-size: 16px;
  font-weight: bold;
  color: var(--primary-color-black);
  font-family: var(--font-family);
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .y83n6hxl .B61t3ohw ._O6Raq1U .__3IRwdzVh {
  font-size: 14px;
  font-weight: normal;
  font-family: var(--font-family);
  color: #a4a9b5;
  line-height: 20px;
  text-align: left;
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .y83n6hxl .B61t3ohw ._O6Raq1U:nth-of-type(2n) {
  border-left: none;
  border-right: none;
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .y83n6hxl .y11q5THO .Q6SN7oCi {
  background-color: var(--primary-color-white);
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .y83n6hxl .y11q5THO .__8jSJar3A {
  width: 46%;
  background-color: var(--primary-color-white);
  border: 1px solid #E8EAED;
  box-sizing: border-box;
  vertical-align: top;
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .y83n6hxl .y11q5THO .__8jSJar3A:first-child {
  width: auto;
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .y83n6hxl .y11q5THO .__8jSJar3A ._fOg_S3C {
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: 600;
  text-align: center;
  color: var(--primary-color-black);
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .y83n6hxl .y11q5THO .__8jSJar3A .qQqDR892 {
  font-size: 12px;
  font-family: var(--font-family);
  text-align: center;
  color: var(--primary-light-grey);
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .y83n6hxl .y11q5THO .__8jSJar3A:nth-of-type(3n+1) {
  text-align: center;
  vertical-align: middle;
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .y83n6hxl .y11q5THO .__8jSJar3A .L030YRxJ {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  align-items: center;
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .y83n6hxl .y11q5THO .__8jSJar3A .L030YRxJ .n8MS2seR {
  width: calc(50% - 0.5rem);
  margin: 0.5rem;
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .y83n6hxl .y11q5THO .__8jSJar3A .L030YRxJ .n8MS2seR:nth-of-type(2n) {
  margin-right: 0;
}
.OxrO0U2I ._bVkAU3d .Yci6iLca .y83n6hxl .y11q5THO .__8jSJar3A .L030YRxJ .n8MS2seR:nth-of-type(2n+1) {
  margin-left: 0;
}
@media (min-width: 1200px) {
  ._bVkAU3d {
    background: var(--primary-color-white);
    padding-bottom: 3rem;
  }
  ._bVkAU3d .Yci6iLca {
    background-color: var(--primary-color-white);
    margin: 0 auto;
    width: var(--max-width);
  }
  .bgbIwCR4 {
    text-align: center;
    margin-bottom: 24px;
  }
  ._lb9d43k {
    font-size: 28px;
    margin-right: 0.5rem;
  }
  .XNmfPqKH {
    font-size: 14px;
    font-family: var(--font-family);
    font-weight: 400;
    text-align: center;
    color: var(--primary-light-grey);
    line-height: 20px;
  }
}
@media (max-width: 1199px) and (min-width: 0px) {
  .OxrO0U2I {
    padding: 0 1rem;
  }
  .bgbIwCR4 {
    margin-top: 1rem;
  }
  ._lb9d43k {
    font-size: 18px;
    margin-bottom: 8px;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #111;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 4px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 4px 4px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: inline-block;
  margin-right: 12px;
  font-size: 12px;
  vertical-align: -1px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: transform 0.24s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
  flex: auto;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-left: auto;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only {
  cursor: default;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
  flex: none;
  cursor: pointer;
}
.ant-collapse > .ant-collapse-item .ant-collapse-icon-collapsible-only {
  cursor: default;
}
.ant-collapse > .ant-collapse-item .ant-collapse-icon-collapsible-only .ant-collapse-expand-icon {
  cursor: pointer;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  left: auto;
  margin: 0;
  transform: translateY(-50%);
}
.ant-collapse-content {
  color: #111;
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}
.ant-collapse-content-hidden {
  display: none;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 4px 4px;
}
.ant-collapse-borderless {
  background-color: #fafafa;
  border: 0;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item:last-child {
  border-bottom: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}
.ant-collapse-ghost {
  background-color: transparent;
  border: 0;
}
.ant-collapse-ghost > .ant-collapse-item {
  border-bottom: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-collapse-rtl {
  direction: rtl;
}
.ant-collapse-rtl.ant-collapse.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 40px;
}
.ant-collapse-rtl.ant-collapse.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 50%;
  right: auto;
  left: 16px;
  margin: 0;
  transform: translateY(-50%);
}
.ant-collapse-rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-right: 0;
  margin-left: 12px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transform: rotate(180deg);
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-right: auto;
  margin-left: 0;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-right: 12px;
  padding-left: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ZhUAGiFq {
  background: linear-gradient(270deg, #00baa1, #00d696 100%);
}
.tVdtkXTo {
  background: linear-gradient(270deg, #00b8fe, #0082fc);
}
.BfMWvc8p {
  background-color: #00baa1;
  color: var(--primary-color-white);
  font-size: 10px;
}
.fwejfdfM {
  background-color: var(--primary-color-blue);
  color: var(--primary-color-white);
  font-size: 10px;
}
._ZRM4p8_ {
  background-color: var(--primary-color-blue);
  color: var(--primary-color-white);
  font-size: 10px;
}
.H7S4TRXd {
  background-color: #E1F8F4;
  transition: all 1s;
}
.H7S4TRXd:hover {
  background: linear-gradient(270deg, #00baa1, #00d696);
  box-shadow: 0px 4px 8px 0px rgba(0, 143, 124, 0.32);
}
.H7S4TRXd:hover .EjmuDhnM {
  color: var(--primary-color-white) !important;
}
.H7S4TRXd:hover .DT1MkAIr .pOktVy_F {
  color: var(--primary-color-white) !important;
}
.H7S4TRXd:hover .DT1MkAIr .Wja9uUfF .BfMWvc8p {
  background-color: var(--primary-color-white) !important;
  color: #00baa1 !important;
}
.j3ELRmTt {
  background-color: #E0F1FF;
  transition: all 1s;
}
.j3ELRmTt:hover {
  background: linear-gradient(90deg, #00b8fe, #0082fc 100%);
  box-shadow: 0px 4px 8px 0px rgba(0, 99, 191, 0.32);
}
.j3ELRmTt:hover .EjmuDhnM {
  color: var(--primary-color-white) !important;
}
.j3ELRmTt:hover .DT1MkAIr .pOktVy_F {
  color: var(--primary-color-white) !important;
}
.j3ELRmTt:hover .DT1MkAIr .Wja9uUfF .fwejfdfM {
  background-color: white!important;
  color: var(--primary-color-blue) !important;
}
.NoInDqoT {
  opacity: 0.68;
  background: #eef3fa;
  cursor: not-allowed;
}
.LGMkR2X4 {
  opacity: 0.68;
  background: #eef3fa;
  cursor: not-allowed;
}
.J_NqZSBh {
  color: #17C2C2;
}
.DDsZWp8Q {
  color: var(--primary-color-blue);
}
.NXDxTDn4 .BGv3W87V {
  height: 20px;
  padding: 0.125rem 0.5rem;
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color-white);
}
.RaX3aZYt .igEeiEcw {
  padding: 0.75rem;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
}
.RaX3aZYt .igEeiEcw .EjmuDhnM {
  font-weight: bold;
  font-size: 14px;
  font-family: var(--font-family);
  line-height: 22px;
  color: var(--primary-color-black);
  opacity: 1;
}
.RaX3aZYt .igEeiEcw .DT1MkAIr {
  display: flex;
  align-items: center;
}
.RaX3aZYt .igEeiEcw .DT1MkAIr .pOktVy_F {
  font-size: 12px;
  font-family: var(--font-family);
}
.RaX3aZYt .igEeiEcw .DT1MkAIr .iXf0Oq7b {
  margin-left: 0.25rem;
}
.Wja9uUfF {
  display: flex;
  align-items: center;
}
.Wja9uUfF .EjmuDhnM {
  font-size: 12px;
}
.Wja9uUfF .drOPcBrS {
  margin-left: 0.5rem;
  width: 1rem;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ZAxPvaKB .P5V1UniA {
  display: flex;
}
.ymcFnPeG {
  border-radius: 50%;
  width: 1rem;
  height: 16px;
  background-color: var(--primary-color-blue);
  color: var(--primary-color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.j_HD8L9P {
  background-color: var(--primary-color-bg);
}
.j_HD8L9P .RdAmgKpD {
  text-align: center;
  font-family: var(--font-family);
  font-weight: 600;
  color: #333333;
  line-height: 30px;
}
.j_HD8L9P .q2Ep5Zu3 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.75rem;
  padding-bottom: 1.5rem;
}
.j_HD8L9P .q2Ep5Zu3 .A3Le7CtB {
  width: 5.875rem;
  height: 24px;
  background-color: var(--primary-color-blue);
  line-height: 24px;
  font-size: 12px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  transition: all 0.3s;
}
.j_HD8L9P .q2Ep5Zu3 .A3Le7CtB:hover {
  opacity: 0.6;
}
.j_HD8L9P .gF6eQNGI .Si0_uDTC .__5qp_XhkU {
  width: 100%;
}
.j_HD8L9P .gF6eQNGI .Si0_uDTC .__5qp_XhkU .X5kNcsAI {
  border: 1px solid #EEF3FA;
  flex: 1;
  margin-right: 1.25rem;
  margin-bottom: 20px;
  height: 60px;
}
.j_HD8L9P .gF6eQNGI .Si0_uDTC .__5qp_XhkU .X5kNcsAI img {
  width: 100%;
  height: 100%;
}
.j_HD8L9P .gF6eQNGI .Si0_uDTC .__5qp_XhkU .X5kNcsAI:nth-of-type(4n) {
  margin-right: 0;
}
@media (min-width: 1200px) {
  .j_HD8L9P {
    padding: 3rem 0;
  }
  .j_HD8L9P .RdAmgKpD {
    font-size: 28px;
  }
  .j_HD8L9P .gF6eQNGI {
    display: flex;
    width: 75rem;
    margin: 0 auto;
  }
  .j_HD8L9P .gF6eQNGI .Si0_uDTC {
    background-color: var(--primary-color-white);
    flex: 1;
    padding: 1.75rem 1.5rem;
    box-sizing: border-box;
    border-radius: 8px;
    padding-bottom: 0;
  }
  .j_HD8L9P .gF6eQNGI .Si0_uDTC .HlLUskwC {
    font-size: 20px;
    font-family: var(--font-family);
    font-weight: 600;
    color: var(--primary-color-black);
  }
  .j_HD8L9P .gF6eQNGI .Si0_uDTC .Igwi_TrJ {
    font-size: 14px;
    font-family: var(--font-family);
    font-weight: 400;
    color: var(--primary-light-grey);
    margin-bottom: 28px;
  }
  .j_HD8L9P .gF6eQNGI .j0a7Mubk {
    padding: 1.75rem 1.5rem;
    margin-left: 1rem;
    width: 25rem;
    padding-bottom: 0;
  }
}
@media (max-width: 1199px) and (min-width: 0px) {
  .j_HD8L9P {
    padding: 1.5rem 0;
    padding-bottom: 0;
  }
  .j_HD8L9P .RdAmgKpD {
    font-size: 20px;
  }
  .j_HD8L9P .Si0_uDTC {
    padding: 0 var(--mobile-all-margin);
    padding-bottom: 0.875rem;
  }
  .j_HD8L9P .Si0_uDTC .HlLUskwC,
  .j_HD8L9P .Si0_uDTC .Igwi_TrJ {
    display: none;
  }
  .j_HD8L9P .Si0_uDTC .__5qp_XhkU .X5kNcsAI {
    margin-right: 0.625rem!important;
    margin-bottom: 10px !important;
  }
  .j_HD8L9P .Si0_uDTC .__5qp_XhkU .X5kNcsAI:nth-of-type(4n) {
    margin-right: 0!important;
  }
  .j_HD8L9P .gF6eQNGI .j0a7Mubk {
    padding: 1.25rem 1rem;
    padding-bottom: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-skeleton {
  display: table;
  width: 100%;
}
.ant-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
.ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 4px;
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}
.ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 4px;
}
.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li {
  border-radius: 100px;
}
.ant-skeleton-active .ant-skeleton-title,
.ant-skeleton-active .ant-skeleton-paragraph > li,
.ant-skeleton-active .ant-skeleton-avatar,
.ant-skeleton-active .ant-skeleton-button,
.ant-skeleton-active .ant-skeleton-input,
.ant-skeleton-active .ant-skeleton-image {
  position: relative;
  /* stylelint-disable-next-line property-no-vendor-prefix,value-no-vendor-prefix */
  z-index: 0;
  overflow: hidden;
  background: transparent;
}
.ant-skeleton-active .ant-skeleton-title::after,
.ant-skeleton-active .ant-skeleton-paragraph > li::after,
.ant-skeleton-active .ant-skeleton-avatar::after,
.ant-skeleton-active .ant-skeleton-button::after,
.ant-skeleton-active .ant-skeleton-input::after,
.ant-skeleton-active .ant-skeleton-image::after {
  position: absolute;
  top: 0;
  right: -150%;
  bottom: 0;
  left: -150%;
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  animation: ant-skeleton-loading 1.4s ease infinite;
  content: '';
}
.ant-skeleton.ant-skeleton-block {
  width: 100%;
}
.ant-skeleton.ant-skeleton-block .ant-skeleton-button {
  width: 100%;
}
.ant-skeleton.ant-skeleton-block .ant-skeleton-input {
  width: 100%;
}
.ant-skeleton-element {
  display: inline-block;
  width: auto;
}
.ant-skeleton-element .ant-skeleton-button {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 4px;
  width: 64px;
  min-width: 64px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-square {
  width: 32px;
  min-width: 32px;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
  width: 32px;
  min-width: 32px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
  border-radius: 32px;
}
.ant-skeleton-element .ant-skeleton-button-lg {
  width: 80px;
  min-width: 80px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-square {
  width: 40px;
  min-width: 40px;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
  border-radius: 40px;
}
.ant-skeleton-element .ant-skeleton-button-sm {
  width: 48px;
  min-width: 48px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-square {
  width: 24px;
  min-width: 24px;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
  width: 24px;
  min-width: 24px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
  border-radius: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-input {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 160px;
  min-width: 160px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-input-lg {
  width: 200px;
  min-width: 200px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-input-sm {
  width: 120px;
  min-width: 120px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-image {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 96px;
  height: 96px;
  line-height: 96px;
}
.ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-image-path {
  fill: #bfbfbf;
}
.ant-skeleton-element .ant-skeleton-image-svg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  max-width: 192px;
  max-height: 192px;
}
.ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
  border-radius: 50%;
}
@keyframes ant-skeleton-loading {
  0% {
    transform: translateX(-37.5%);
  }
  100% {
    transform: translateX(37.5%);
  }
}
.ant-skeleton-rtl {
  direction: rtl;
}
.ant-skeleton-rtl .ant-skeleton-header {
  padding-right: 0;
  padding-left: 16px;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  animation-name: ant-skeleton-loading-rtl;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  animation-name: ant-skeleton-loading-rtl;
}
@keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.GuP3nJyv {
  background-color: var(--primary-color-white);
  border-radius: 8px;
  box-sizing: border-box;
}
.zgXrpS6z {
  font-size: 20px;
  font-family: var(--font-family);
  font-weight: bold;
  color: #17243e;
  margin-bottom: 6px;
}
.BoYqsJJy {
  padding: 0.75rem 0;
  border-bottom: 1px solid #E8E8E8;
  display: block;
  transition: all 0.3s;
}
.BoYqsJJy:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.__6asVPYAd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.__6asVPYAd:last-child {
  margin-bottom: 0;
}
.__6asVPYAd .oaDBf204 {
  color: #ff7a45 !important;
}
.__6asVPYAd .NOHlLozq {
  font-size: 16px;
  font-family: var(--font-family);
  font-weight: bold;
  color: var(--primary-color-black);
  white-space: nowrap;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.__6asVPYAd .WGZXq1ld {
  font-size: 14px;
  font-family: var(--font-family);
  color: var(--primary-color-grey);
  white-space: nowrap;
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.__6asVPYAd .TS6DsOuD {
  font-size: 12px;
  font-family: var(--font-family);
  color: #c5c7c4;
}
.__6asVPYAd .hukiZRvD {
  height: 24px;
  padding: 0.3125rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--primary-color-blue);
  border-radius: 100px;
  font-size: 12px;
  background-color: #E5F2FE;
  font-family: var(--font-family);
}
.BoYqsJJy:last-child {
  border-bottom: none;
}
.GjJgnEn0 {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1791FF;
  font-size: 14px;
}

